import React from 'react';
import './Reveal.css';
import { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Lottie from 'lottie-web-react';
import iconShop from './Assets/Animation/shop.json';
import iconAR from './Assets/Animation/ar.json';
import iconCode from './Assets/Animation/code.json';
import SkyboxImage from './Assets/Images/space-skybox.jpg';
import EnvironmentImage from './Assets/Images/space-env.jpg';
import FadeIn from "react-fade-in";
import ContentLoader from "react-content-loader";
import { Trans, useTranslation } from 'react-i18next';
import i18n from './i18n';
import '@google/model-viewer/dist/model-viewer';
import CallToAction from './CallToAction';
import logoAcer from './Assets/Images/acer.svg';
import logoTmall from './Assets/Images/tmall.svg';
import { Helmet } from 'react-helmet';
import QRCode from 'qrcode.react';

var _ = require('underscore');

const SCROLL_TRAVEL_DISTANCE = 400;
export const MODEL_PASSCODE = [
  {glb: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBTQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--896c70c09150ff38e2bfd2c0877d0fd7d1cfd4f1/PredatorGamingChair-53G9D.glb", usdz: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBTUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0238570d45c8400fc7f6669b40bdccfa04013db0/PredatorGamingChair-53G9D.usdz", passcode: "53G9D"},
  {glb: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBTZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b247b3a0dc125478ccdf9ac77ae0f78a3397b745/PredatorGamingChair-86TNQ.glb", usdz: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBTdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--913aa50b9c375a20243e9e43b6acf8d5a319279d/PredatorGamingChair-86TNQ.usdz", passcode: "86TNQ"},
  {glb: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBUQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c7b8b7110f19f0f20b47ca5809424e005af1cb3c/PredatorGamingChair-NRP7T.glb", usdz: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBUUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--36591c5f6198c79adaa87e6b808cf580a0b1c15e/PredatorGamingChair-NRP7T.usdz", passcode: "NRP7T"},
  {glb: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBUZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3103f510b388c3582e85752c2b441016f5cd82a0/PredatorGamingChair-PRVRW.glb", usdz: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBUdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c20abd7d4575cff1f12e61f62e642ff6e91d8f85/PredatorGamingChair-PRVRW.usdz", passcode: "PRVRW"},
  {glb: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBVQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6d50aa9dabf2676761027e39122df55811294aba/PredatorGamingChair-V4J6B.glb", usdz: "https://staging.realityfab.com/entities/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBVUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c99a9768f0dfb4b2f96011299d3fc33992f0d515/PredatorGamingChair-V4J6B.usdz", passcode: "V4J6B"},
]
const INDEX_LIST = [0, 1, 2, 3, 4];

function interpolate(x, xMin, xMax, yMin, yMax, clipMin = true) {
  if (clipMin) {
    return Math.min(Math.max( ( (x - xMin) * (yMax - yMin) / (xMax - xMin) ) + yMin, yMin), yMax);
  }
  return Math.min(( (x - xMin) * (yMax - yMin) / (xMax - xMin) ) + yMin, yMax);
}

function calcHeaderOffset(scrollY) {
  return Math.min(scrollY, SCROLL_TRAVEL_DISTANCE);
}

function pageShallFixed(scrollY) {
  return calcHeaderOffset(scrollY) < SCROLL_TRAVEL_DISTANCE;
}

function maskShallHide(scrollY) {
  return scrollY > SCROLL_TRAVEL_DISTANCE - 100;
}

function calcLogoScale(scrollY) {
  return interpolate(scrollY, 0, SCROLL_TRAVEL_DISTANCE, 1, 10);
}

function calcLogoOpacity(scrollY) {
  const o = 1 - interpolate(scrollY, SCROLL_TRAVEL_DISTANCE - 300, SCROLL_TRAVEL_DISTANCE - 100, 0, 1);
  return o;
}

function Stores(props) {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="mb-3"><Trans>stores</Trans></h2>
      <ul className="stores d-inline-flex flex-row">
        <li><a href={`https://store.acer.com/zh-tw/predator-osim-chair?track_code=${props.trackCode}`} alt={t('acer_store')} title={t('acer_store')} className="store" target="_blank" rel="noopener noreferrer"><img src={logoAcer} alt={t('acer_store')} /></a></li>
        <li><div alt={t('coming_soon')} title={t('coming_soon')} className="store disabled" rel="noopener noreferrer"><img src={logoTmall} alt={t('tmall_store')} /></div></li>
      </ul>
    </div>
  );
}

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="row footer d-flex flex-column align-items-center">
      <div className="divider"></div>
      <a href="https://www.acer.com/ac/zh/TW/content/predator-gamingchairxosim" className="product-page-link" target="_blank" rel="noopener noreferrer" role="button" title={t("find_out_more")}>&nbsp;</a>
      <div className="social mt-3 mb-3 d-flex flex-row">
        <a href="https://www.facebook.com/Predator-Global-1529754183989524" className="facebook"><FontAwesomeIcon icon={faFacebookF} transform="grow-4"/></a>
        <a href="https://www.youtube.com/channel/UCvvsIq9TIfMLPVHRsZaR2Ag" className="youtube"><FontAwesomeIcon icon={faYoutube} transform="grow-4"/></a>
        <a href="https://www.instagram.com/predatorgaming/" className="instagram"><FontAwesomeIcon icon={faInstagram} transform="grow-8"/></a>
      </div>
        <div className="credit mb-4"><Trans i18nKey="acknowledge">Designed and hosted by <a href="https://www.diing.com"><span class="diing">Diing</span></a></Trans></div>
    </div>
  );
}

function CampaignPage() {
  const DEBUG_LOADING = false;
  
  const mainRef = useRef(null);
  const wrapperRef = useRef(null);
  const modelViewerRef = useRef(null);

  const [scrollY, setScrollY] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [codes, setCodes] = useState([null,null]);

  const [modelPasscodeIndex, setModelPasscodeIndex] = useState(_.sample(INDEX_LIST));
  const fetchCodePair = async () => {
    let params = new URLSearchParams(window.location.search);

    let refCode = (params.get('ref') === null ? "root" : params.get('ref'));
    let trcCode = ""

    fetch(`/codes?ref=${refCode}`)
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      trcCode = result.trc;
      setCodes([refCode, trcCode]);
    })
    .catch((err) => {
      // TODO
    });
  }
  useEffect(() => {
    if (codes[0] === null && codes[1] === null) {
      fetchCodePair();
    }
  });

  useLayoutEffect(() => {
    wrapperRef.current.style.height = mainRef.current.offsetHeight + "px";
  });

  useEffect(() => {
    function handleResize() {
      handleScroll();
    }

    function handleScroll() {
      setScrollY(window.scrollY);
    }

    function handleModelViewerLoaded(evt) {
      setLoading(false);
    } 

    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    modelViewerRef.current.addEventListener('load', handleModelViewerLoaded);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div ref={wrapperRef}>
      <Helmet
        title={t('title')}
        meta={[
          {charset: "utf-8"},
          {property: "og:type", content: "website"},
          {property: "og:title", content: t('og_title')},
          {property: "og:url", content: "https://predator-x-osim.com"},
          {property: "og:description", content: t('og_description')},
          {property: "og:site_name", content: t('og_site_name')},
          {property: "og:image", content: `${process.env.PUBLIC_URL}/images/predatorgamingchair.jpg`},
        ]}
      >
      </Helmet>
      <div ref={mainRef} className={`main container-fluid${pageShallFixed(scrollY) ? " position-fixed w-100" : ""}`} style={{transform: pageShallFixed(scrollY) ? "none" : `translate(0, ${SCROLL_TRAVEL_DISTANCE}px)`}}>
        <div className="row">
            <div className="showcase overflow-hidden w-100 bg-dark position-relative">
              <div className="chair h-100 w-100">
                <div className="realityfab-wrapper">
                  <model-viewer
                    ref={modelViewerRef}
                    style={{width: "100%", height: "100%", background: "#222", visibility: loading ? "hidden" : "visible"}}
                    src={MODEL_PASSCODE[modelPasscodeIndex].glb}
                    ios-src={MODEL_PASSCODE[modelPasscodeIndex].usdz}
                    skybox-image={SkyboxImage}
                    environment-image={EnvironmentImage}
                    ar interaction-policy="allow-when-focused"
                    autoplay auto-rotate
                    interaction-prompt="when-focused"
                    interaction-prompt-threshold="1000"
                    quick-look-browsers="safari chrome"
                    ar-modes="scene-viewer webxr quick-look"
                    ar-scale="auto" camera-controls alt="Predator Gaming Chair x OSIM">
                  </model-viewer>
                  {
                    loading || DEBUG_LOADING
                    ? <ContentLoader
                        style={{width:"100%", height:"100%", position: "absolute", top: 0, left: 0}}
                        speed={3}
                        backgroundColor="#212121"
                        foregroundColor="#143f47"
                        title={<Trans>loading</Trans>}
                      >
                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
                      </ContentLoader>
                    : null
                  }
                </div>
              </div>
              <div className={`mask w-100 h-100 position-absolute flex-column ${maskShallHide(scrollY) ? "d-none" : "d-flex"}`} style={{transform: `scale(${calcLogoScale(scrollY)})`, opacity: calcLogoOpacity(scrollY)}}>
                <div className="top-part w-100 flex-grow-1"></div>
                <div className="center-part w-100 d-flex flex-row">
                  <div className="left-part flex-grow-1">&nbsp;</div>
                  <div className="middle-part">&nbsp;</div>
                  <div className="right-part flex-grow-1">&nbsp;</div>
                </div>
                <div className="bottom-part w-100 flex-grow-1"></div>
              </div>
            </div>
        </div>
        <div className="row">
          <div className="container d-flex justify-content-center flex-column flex-md-row mt-5 mb-4">
            <div className="event flex-grow-1" style={{background: "black"}}>
              <h1>{t('head')}</h1>
              <p className="subline"><Trans>mission_description</Trans></p>
              <ul className="d-flex flex-column align-items-left rules">
                <li>
                  <div className="icon ar-lottie"><Lottie className="lottie-icon" options={{autoplay: true, animationData: iconAR, loop: true}} playingState={"play"}></Lottie></div>
                  <div className="description flex-grow-1">{t('description1')}</div>
                </li>
                <li>
                  <div className="icon code-lottie"><Lottie className="lottie-icon" options={{autoplay: true, animationData: iconCode, loop: true}} playingState={"play"}></Lottie></div>
                  <div className="description flex-grow-1">{t('description2')}</div>
                </li>
                <li>
                  <div className="icon shop-lottie"><Lottie className="lottie-icon" options={{autoplay: true, animationData: iconShop, loop: true}} playingState={"play"}></Lottie></div>
                  <div className="description flex-grow-1"><Trans>description3</Trans></div>
                </li>
                <li><div className="footnote"><Trans i18nKey="compatibility_note">* Support iOS12+ iPhone and iPad, and Google Chrome v83+ in an <a href="https://developers.google.com/ar/discover/supported-devices" target="_blank">ARCore supported Android device</a>.</Trans></div></li>
              </ul>
              <CallToAction selectedIndex={modelPasscodeIndex} refCode={codes[0]} trackCode={codes[1]}/>
              <Stores refCode={codes[0]} trackCode={codes[1]} />
            </div>
            <div className="ml-md-4 d-none d-md-flex flex-column mb-4 qrcode-section">
              <div className="qrcode">
                <QRCode 
                  value={`${window.location.href}`}
                  renderAs='svg'
                  size={200}
                  includeMargin={true}
                />
              </div>
              <div className="p-2 footnote"><Trans>scan_qrcode</Trans></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CampaignPage;
