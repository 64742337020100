import React, { useState, useRef } from 'react';
import './CallToAction.css';
import { Trans, useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt, faLock, faKey } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'lottie-web-react';
import iconChest from './Assets/Animation/chest.json';
import iconChestOpen from './Assets/Animation/chest_open.json';
import i18n from './i18n';
import PinInput from 'react-pin-input';
import { MODEL_PASSCODE } from './Reveal';

const PASSCODE = ["53G9D", "86TNQ", "PRVRW", "V4J6B", "NRP7T"];

function RedeemCodePage(props) {
    const codeField = useRef(null);
    const codeOnClick = (event) => {
        codeField.current.select();
    }

    return (
        <div className="redeem w-100">
            <div className="header">
                <div className="icon"><Lottie options={{autoplay: true, animationData: iconChestOpen, loop: true}} playingState={"play"}></Lottie></div>
                <h2 className="title"><Trans>redeem_title</Trans></h2>
                <p><Trans>redeem_description</Trans></p>
            </div>
            <div className="code-section flex-column justify-content-center align-items-center d-flex mt-3 mb-2">
                <input type="text" readOnly className="code" ref={codeField} onClick={codeOnClick} size="20" value={props.trackCode} />
            </div>
        </div>
    );
}

function CallToAction(props) {
    const { t } = useTranslation();
    const emailField = useRef(null);
    const [passcodeValue, setPasscodeValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [showRedeem, setShowRedeem] = useState(false);
    const [passcodeError, setPasscodeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const debugPasscodeError = false;

    const checkPasscode = () => {
        if (PASSCODE.includes(passcodeValue)) {
            sendToBackend();
            setPasscodeError(false);
        } else {
            setPasscodeError(true);
        }
    }

    const sendToBackend = () => {
        fetch(`/show?passcode=${passcodeValue}&email=${emailValue}&tc=${props.trackCode}&rc=${props.refCode}`)
        .then((res) => {
            return res.json();
        })
        .then((result) => {

            if (result.res) {
                setShowRedeem(true);
            } else {
                if(result.msg) {
                    setEmailError(true);
                } else {
                    setPasscodeError(true);
                }
            }
        })
        .catch((err) => {
          // TODO
        });
    }

    const handleSubmit = (event) => {
        checkPasscode();
        event.preventDefault();
    }

    const validateLegalPasscode = (string) => {
        return string.length === 5;
    }

    const validateLegalEmail = (string) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(string)
    }

    const handleEmailFieldChange = (event) => {
        setEmailValue(event.target.value);
    }

    const handlePasscodeFieldChange = (value, index) => {
        setPasscodeValue(value);
    }

    const validateFormCompletion = () => {
        return validateLegalEmail(emailValue) && validateLegalPasscode(passcodeValue);
    }

    const handlePasscodeFieldComplete = (value, index) => {
        if (index === 4) {
            emailField.current.focus();
        }
    }
    return (
        <div className="claim px-4 pt-2 pb-4 mb-5 d-flex flex-column align-items-center">
            <SwitchTransition>
                <CSSTransition
                    key={showRedeem}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                      }}
                    classNames="claimSwitch">
                    {showRedeem
                    ? <RedeemCodePage trackCode={props.trackCode} />
                    : (
                        <form id="claim" onSubmit={handleSubmit}>
                            <div className="form-group header">
                                <div className="icon"><Lottie options={{autoplay: true, animationData: iconChest, loop: true}} playingState={"play"}></Lottie></div>
                                <h2 className="title"><Trans>claim_form_title</Trans></h2>
                                <p className="subline"><Trans>claim_form_description</Trans></p>
                            </div>
                            <div className="form-group d-flex flex-column align-items-center">
                                <label htmlFor="claimPasscode"><FontAwesomeIcon icon={faKey} transform="shrink-4"/>&nbsp;<Trans>passcode</Trans></label>
                                <PinInput
                                    length={5}
                                    type="custom"
                                    onChange={handlePasscodeFieldChange}
                                    onComplete={handlePasscodeFieldComplete}
                                />
                                {passcodeError
                                    ? <small id="error" className="form-text text-danger"><Trans>passcode_error</Trans></small>
                                    : null
                                }
                                <input type="hidden" value={passcodeValue} />
                            </div>
                            <div className="form-group row">
                                <label htmlFor="claimEmail" className="col-sm-2 col-form-label"><Trans>email</Trans></label>
                                <div className="col-sm-10 d-flex flex-column">
                                    <input ref={emailField} type="email" className="form-control input-main" id="claimEmail" value={emailValue} placeholder={t('email_placeholder')} onChange={handleEmailFieldChange}/>
                                    {emailError
                                        ? <small id="error" className="form-text text-danger"><Trans>email_error</Trans></small>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="form-group row mt-4">
                                <div className="col-12 d-flex flow-row justify-content-center">
                                    <button type="submit" className="btn btn-primary btn-main" disabled={!validateFormCompletion()}><FontAwesomeIcon icon={validateFormCompletion() ? faUnlockAlt : faLock} transform="up-1" />&nbsp;&nbsp;<Trans>claim_button</Trans></button>
                                </div>
                            </div>
                        </form>
                    )
                }
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default CallToAction;